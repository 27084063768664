import { Box, Typography, Button, TextField } from "@mui/material";
import { useMediaQueries } from "../../breakPoints";

export default function HomeForm() {
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();
  return (
    <Box
      sx={{
        height: isDesktop ? "50vh" : isMobile ? "50vh" : "30vh",
        width: "100vw",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Box
        sx={{
          height: "60%",
          width: "80%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#000",

            fontFamily: "Inter Normal",
            fontSize: isMobile ? "12px" : isTablet ? "14px" : "16px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: "normal",
            mb: isMobile && 1,
          }}
        >
          Subscribe to receive a monthly digest of our most valuable resources
          like blog posts, whitepapers and guides.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile && "center",
          }}
        >
          <TextField
            placeholder="Email"
            InputProps={{ sx: { borderRadius: "15px" } }}
            sx={{
              height: isMobile ? "42px" : isTablet ? "42px" : "62px",
              width: isMobile ? "80vw" : isTablet ? "40vh" : "412px",
            }}
          ></TextField>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2B0D4B",
              height: isMobile ? "52px" : "53px",
              // width: isMobile && "15vh",
              mt: isMobile && 3,
              borderRadius: isMobile ? "8px" : "15px",
              fontSize: isMobile && "12px",
              ml: 2,
            }}
          >
            Subscribe
          </Button>
        </Box>

        <Typography
          sx={{
            color: "#000",

            fontFamily: "Inter Normal",
            fontSize: isMobile ? "12px" : isTablet ? "14px" : "16px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          Unsubscribe anytime. Your data is stored for business-to-business
          communication purposes. See our privacy policy
        </Typography>
      </Box>
    </Box>
  );
}
