import { React } from "react";
import { Box, Grid, Typography, Card, Button } from "@mui/material";
import cyberSecurity from "../../assets/cybersecurity.svg";
import softwareLicense from "../../assets/softwarelicense.svg";
import complianceService from "../../assets/complianceservice.svg";
import informationSecurity from "../../assets/informationsecurity.svg";
import dataVizualization from "../../assets/datavisualization.svg";
import managedService from "../../assets/managedservice.svg";
import { useMediaQueries } from "../../breakPoints";

export default function ServicesList() {
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();
  const LabelFont = isTablet ? "14px" : isMobile ? "12px" : "18px";

  const servicesList = [
    {
      name: "Cyber Security",
      description:
        "Provides frameworks to help developers secure their applications by finding, fixing and monitoring your web, mobile and networks against current and future vulnerabilities",
      color: "#C1E3F8",
      image: cyberSecurity,
    },
    {
      name: "Software Licensing Solution",
      description:
        "Get unbiased, neutral advice with our vendor agnostic licensing recommendations",
      color: "#DCE6FF",
      image: softwareLicense,
    },
    {
      name: "Compliance Service",
      description:
        "ISO certifcations for various industries, Vulnerability Assessment, IOT Security, ISMS",
      color: "#C1E3F8",
      image: complianceService,
    },
    {
      name: "Information Security  Compliance ",
      description:
        "We offer Payment Card Industry - Data Security Standard (PCI-DSS), Health Insurance Portability and Accountability Act (HIPAA) and various other compliance requirements in a cost effective manner",
      color: "#BEDEFA",
      image: informationSecurity,
    },
    {
      name: "Data Analytics and Visulization",
      description:
        "Gather Hidden Insights, Generate Reports, Perform Market Analysis, Improve Business Requirement",
      color: "#DAD4FF",
      image: dataVizualization,
    },
    {
      name: "Managed Services",
      description:
        "Handles specialized business functions on the behalf of a company through outsourcing.",
      color: "#C1E3F8",
      image: managedService,
    },
  ];
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "top",
      }}
    >
      <Typography
        sx={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Inter Normal",
          fontSize: isMobile ? "30px" : isTablet ? "40px" : "50px",
          fontWeight: 700,
          mt: 2,
          mb: 2,
          lineHeight: "normal",
        }}
      >
        Services
      </Typography>

      <Typography
        sx={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Inter Normal",
          fontSize: LabelFont,
          fontWeight: 400,
          width: isMobile ? "80%" : isTablet ? "80%" : "50%",
          lineHeight: "normal",
        }}
      >
        Technowtechies is well renowned as a leading IT Transformation Architect
        company, with an impeccable track record for designing, developing,
      </Typography>

      <Grid
        container
        spacing={2}
        direction="row"
        sx={{
          width: "80vw",
          minheight: "100vh",
          mt: 4,
          mb: 4,
          display: "flex",
          flexGrow: 1,
        }}
      >
        {servicesList.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "485px",
            }}
          >
            <Card
              sx={{
                height: "90%",
                width: "90%",
                boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  height: "50%",
                  width: "100%",
                  backgroundColor: service.color,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={service.image} />
              </Box>

              <Typography
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Inter Normal",
                  fontSize: isMobile ? "14px" : isTablet ? "16px" : "18px",
                  fontWeight: 700,
                  ml: 2,
                  mr: 2,
                  lineHeight: "normal",
                }}
              >
                {service.name}
              </Typography>

              <Typography
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Inter Normal",
                  fontSize: isMobile ? "10px" : isTablet ? "12px" : "14px",
                  fontWeight: 400,
                  ml: 2,
                  mr: 2,
                  lineHeight: "normal",
                }}
              >
                {service.description}
              </Typography>

              <Button
                sx={{
                  mb: 2,
                  textTransform: "none",
                  height: "45px",
                  width: "145px",
                  alignSelf: "center",
                  border: "1px solid #1B45A1",
                  borderRadius: "15px",
                }}
              >
                Know more
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
