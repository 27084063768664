import { Box, IconButton, Typography } from "@mui/material";
import frame from "../../assets/Frame.svg";
import { useMediaQueries } from "../../breakPoints";

export default function Description() {
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();

  const headingFont = isTablet ? "40px" : isMobile ? "20px" : "60px";
  return (
    <Box
      sx={{
        width: "100vw",
        height: isMobile ? "40vh" : isTablet ? "60vh" : "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton disableRipple>
        <img
          src={frame}
          height={isTablet ? "400px" : isMobile && "200px"}
          width={isTablet ? "400px" : isMobile && "200px"}
        />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "30%",
          // backgroundColor: "green",

          ml: isMobile ? 1 : isTablet ? 3 : 5,
        }}
      >
        <Typography
          sx={{
            color: "#000",
            textAlign: "left",
            fontFamily: "Inter Normal",
            fontSize: headingFont,
            fontWeight: 700,

            lineHeight: "normal",
          }}
        >
          Technow Techies
        </Typography>

        <Typography
          sx={{
            color: "#000",
            textAlign: "left",
            fontFamily: "Inter Normal",
            fontSize: isMobile ? "8px" : isTablet ? "16px" : "18px",
            fontWeight: 400,

            lineHeight: "normal",
          }}
        >
          Technowtechies is well renowned as a leading IT Transformation
          Architect company, with an impeccable track record for designing,
          developing, deploying and delivering end-to-end IT Infrastructure
          services. Delivers cutting-edge IT services and solutions to a wide
          range of industries
        </Typography>
      </Box>
    </Box>
  );
}
