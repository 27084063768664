import { Box, IconButton, Typography } from "@mui/material";
import background from "../../assets/bg.png";
import { useMediaQueries } from "../../breakPoints";

export default function PageTitleBox(props) {
  const { pageTitleDetails, selectedPage } = props;
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();

  const headingFont = isTablet ? "40px" : isMobile ? "30px" : "60px";
  const LabelFont = isTablet ? "18px" : isMobile ? "16px" : "18px";
  console.log(selectedPage);

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: isMobile ? "80vh" : isTablet ? "70vh" : "100vh",
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          minHeight: isMobile ? "60vh" : isTablet ? "40vh" : "70vh",
          width: "90vw",
          borderRadius: "12px",
          p: 1,
          backgroundColor:
            "fill: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 55.73%, rgba(255, 255, 255, 0.20) 155.73%)",
          strokeWidth: "1px",
          stroke: "#FFF",
          backdropFilter: "blur(20px)",
          display: "flex",
          flexDirection: isMobile
            ? "column"
            : selectedPage !== 0
            ? "column"
            : "row",

          // flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: isMobile ? "80%" : "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: isDesktop ? selectedPage !== 0 && 10 : 0,
            ml: 3,
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#2B0D4B",
                fontSize: headingFont,
                fontWeight: 700,
                lineHeight: "normal",
                textAlign: "left",
              }}
            >
              {pageTitleDetails.title}
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "#000",
              textAlign: "left",
              fontFamily: "Inter Normal",
              fontSize: LabelFont,
              fontWeight: 400,
              mt: isDesktop ? 3 : null,
              mb: isDesktop ? 0 : null,
              lineHeight: isDesktop ? "normal" : null,
            }}
          >
            {pageTitleDetails.description}
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <img
            src={pageTitleDetails.icon}
            height={isDesktop ? "503" : isTablet ? "300" : isMobile && "300"}
            width={isDesktop ? "503" : isTablet ? "300" : isMobile && "300"}
          />
        </Box>
      </Box>
    </Box>
  );
}
