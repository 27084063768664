import {
  Grid,
  Typography,
  Box,
  TextField,
  IconButton,
  Divider,
  Link,
} from "@mui/material";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";
import { useMediaQueries } from "../../breakPoints";

const links = [
  {
    name: "Home",
  },
  {
    name: "service",
  },
  {
    name: "About Us",
  },
  {
    name: "Privacy Policy",
  },
  {
    name: "Terms & conditions",
  },
  {
    name: "cookie policy",
  },
];

const socialMedia = [
  {
    logo: facebook,
  },
  {
    logo: twitter,
  },
  {
    logo: instagram,
  },
];

export default function Footer() {
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();
  return (
    <Box
      sx={{
        height: isMobile ? "100vh" : "50vh",
        width: "100vw",
        backgroundColor: "#2B0D4B",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        placeItems: "center",
      }}
    >
      <Grid container spacing={2} sx={{ height: "80%", width: "80%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1 }}>
              <Typography
                sx={{
                  color: "#FFF",

                  fontFamily: "Inter Normal",
                  fontSize: "25px",
                  fontWeight: 400,
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                TechNow Techies
              </Typography>
              <div>
                <p
                  style={{
                    color: "#FFF",
                    fontFamily: "Inter Normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "justify",
                    width: "100%",

                    lineHeight: "normal",
                  }}
                >
                  Technowtechies is well renowned as a leading IT Transformation
                  Architect company,with an impeccable track record for
                  designing, developing,deploying and delivering end-to-end IT
                  Infrastructure services. Delivers cutting-edge IT services and
                  solutions to a wide range of industries
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Grid container spacing={3}>
                {links.map((link) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ mt: isMobile && 1 }}
                  >
                    <Typography
                      sx={{
                        color: "#FFF",

                        fontFamily: "Inter Normal",
                        fontSize: "14px",
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: "normal",
                        textAlignLast: "center",
                      }}
                    >
                      {link.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  alignItems: "center",
                  width: "100%",
                  mt: isMobile && 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",

                    fontFamily: "Inter Normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "left",
                    lineHeight: "normal",
                  }}
                >
                  Follow us on
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "space-between",
                  }}
                >
                  {socialMedia.map((social) => (
                    <IconButton>
                      <img src={social.logo} />
                    </IconButton>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: "grid", placeItems: "start" }}
        >
          <Divider sx={{ width: "100%", backgroundColor: "#FFF" }} />
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Inter Normal",
                fontSize: "14px",
                fontWeight: 400,

                lineHeight: "normal",

                mt: 3,
              }}
            >
              {" "}
              © Technow Techies 2023. All rights reserved
            </Typography>


          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
