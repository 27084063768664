import cyberSecurity from "../../assets/cybersecurity.svg";
import softwareLicense from "../../assets/softwarelicense.svg";
import complianceService from "../../assets/complianceservice.svg";
import informationSecurity from "../../assets/informationsecurity.svg";
import dataVizualization from "../../assets/datavisualization.svg";
import managedService from "../../assets/managedservice.svg";
import { Grid, Typography, Box, Card, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const servicesList = [
  {
    name: "Cyber Security",
    image: cyberSecurity,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
  {
    name: "Software Licensing Solution",
    image: softwareLicense,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
  {
    name: "Compliance Service",
    image: complianceService,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
  {
    name: "Information Security  Compliance ",
    image: informationSecurity,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
  {
    name: "Data Analytics and Visulization",
    image: dataVizualization,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
  {
    name: "Managed Services",
    image: managedService,
    mainCardTypography:
      "Technowtechies is well renowned as a leading IT Transformation Architect company, with an impeccable track record for designing, developing, deploying and delivering end-to-end IT Infrastructure services.Delivers cutting-edge IT services and solutions to a wide range of industries",
    placard1:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
    placard2:
      "Do away with complicated Excel sheets – Saves up to 60% - 70% of planning efforts & improve accuracy up to 90% - 95%",
  },
];

export default function Servicesdetails() {
  return (
    <Box
      sx={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        mt: 5,
      }}
    >
      <Grid container spacing={3} sx={{ width: "90%", height: "20vh" }}>
        {servicesList.map((service, index) => (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{
                  color: "#2B0D4B",

                  fontFamily: "Inter Normal",
                  fontSize: "35px",
                  fontWeight: 700,
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                {service.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  sx={{
                    backgroundColor: "#E8E6FB",
                    borderRadius: "40px",
                    height: "60vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "90%",
                      width: "60%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={service.image} height="60%" width="70%" />
                    <Typography
                      sx={{
                        color: "#2B0D4B",

                        fontFamily: "Inter Normal",
                        fontSize: "16px",
                        fontWeight: 400,
                        textAlign: "justify",
                        lineHeight: "normal",
                        mt: 2,
                      }}
                    >
                      {service.mainCardTypography}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card
                        sx={{
                          height: "200px",
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "#2B0D4B",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            width: "95%",
                            backgroundColor: "#FFF",
                          }}
                        ></Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card
                        sx={{
                          height: "200px",
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "#2B0D4B",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            width: "95%",
                            backgroundColor: "#FFF",
                          }}
                        ></Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>

      {/* {servicesList.map((service, index) => (
        <Grid container spacing={2} sx={{ width: "80%", height: "20vh" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>{service.name}</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))} */}
    </Box>
  );
}
