import logo from './logo.svg';
import { BrowserRouter, useLocation, Routes, Route } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import AboutUSScreen from "./Screens/AboutUsScreen";
import ServicesScreen from "./Screens/ServicesScreen";
import HomeScreen from "./Screens/HomeScreen";
import { useState } from "react";

function App() {
  const [selectedPage, setSelectedPage] = useState();
  const theme = createTheme({
    typography: "DM Sans Regular",
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1425,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            index={true}
            element={
              <HomeScreen
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            }
          />
          <Route
            path="/services"
            element={
              <ServicesScreen
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            }
          />
          <Route
            path="aboutus"
            element={
              <AboutUSScreen
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
