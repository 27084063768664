import { Box, Toolbar, Typography } from "@mui/material";
import MenuItems from "../components/Common/Appbar";
import { useEffect } from "react";
import PageTitleBox from "../components/Common/pageTitleBox";
import background from "../assets/bg.png";
import servicesMainPic from "../assets/servicesMainPic.png";
import TitleBox from "../components/Common/services&aboutUsTitleBox";
import Services from "../components/servicesPageComponents/services";
import Servicesdetails from "../components/servicesPageComponents/servicesDetails";

export default function ServicesScreen(props) {
  const { selectedPage, setSelectedPage } = props;
  const pageTitleDetails = {
    title: "Services",
    description:
      "Preparing for Your Success , We Provide Truly Prominent IT Solutions",
    icon: servicesMainPic,
  };
  useEffect(() => {
    setSelectedPage(1);
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        <MenuItems selectedPage={selectedPage} />
        <Toolbar />
        <TitleBox pageTitleDetails={pageTitleDetails} selectedPage={1} />
        <Services />
        <Servicesdetails />
      </Box>
    </div>
  );
}
