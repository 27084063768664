import { useEffect } from "react";
import MenuItems from "../components/Common/Appbar";
import PageTitleBox from "../components/Common/pageTitleBox";
import { Toolbar } from "@mui/material";
import background from "../assets/bg.png";
import frame from "../assets/Frame.svg";
import TitleBox from "../components/Common/services&aboutUsTitleBox";

export default function AboutUSScreen(props) {
  const { selectedPage, setSelectedPage } = props;
  const pageTitleDetails = {
    title: "About US",
    description:
      "Preparing for Your Success , We Provide Truly Prominent IT Solutions",
    icon: frame,
  };

  useEffect(() => {
    setSelectedPage(2);
  }, []);

  return (
    <>
      <MenuItems selectedPage={selectedPage} />
      <Toolbar />
      <TitleBox pageTitleDetails={pageTitleDetails} selectedPage={2} />
    </>
  );
}
