import cyberSecurity from "../../assets/cybersecurity.svg";
import softwareLicense from "../../assets/softwarelicense.svg";
import complianceService from "../../assets/complianceservice.svg";
import informationSecurity from "../../assets/informationsecurity.svg";
import dataVizualization from "../../assets/datavisualization.svg";
import managedService from "../../assets/managedservice.svg";
import { Grid, Typography, Box } from "@mui/material";

const servicesList = [
  {
    name: "Cyber Security",
    image: cyberSecurity,
  },
  {
    name: "Software Licensing Solution",
    image: softwareLicense,
  },
  {
    name: "Compliance Service",
    image: complianceService,
  },
  {
    name: "Information Security  Compliance ",
    image: informationSecurity,
  },
  {
    name: "Data Analytics and Visulization",
    image: dataVizualization,
  },
  {
    name: "Managed Services",
    image: managedService,
  },
];

export default function Services() {
  return (
    <Box
      sx={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={2} sx={{ width: "80%" }}>
        {servicesList.map((service, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={service.image} height="300px" width="300px" />
              <Typography
                sx={{
                  color: "#2B0D4B",

                  fontFamily: "Inter Normal",
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                {service.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
