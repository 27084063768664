import { Box, Typography } from "@mui/material";
import productdescBackground from "../../assets/productdescbg.png";
import productBackground from "../../assets/productbg.svg";
import hr from "../../assets/hr.svg";
import assets from "../../assets/assets.svg";
import pos from "../../assets/pos.svg";
import erp from "../../assets/erp.svg";
import eshopping from "../../assets/eshopping.svg";
import test from "../../assets/test.svg";
import { useMediaQueries } from "../../breakPoints";
export default function ProductList() {
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();
  const productList = [
    {
      name: "Your HR",
      description:
        "“Your HR” is the HRMS solution is a single digital platform to manage attendance, payroll, Leaves and tax calculation and declarations. Also our tool will automate the routine tasks and leverage self-services for all the HRMS solutions.",
      image: hr,
    },
    {
      name: "Assets",
      description:
        "“Assets” is the asset management system. Get a one stop solution for all the asset management issues, “Assets” is our digital platform to enable all your Assets and can track the assets on the go. Managing new assets to the organization is easy now with “Assets”.",
      image: assets,
    },
    {
      name: "Pass The Test",
      description:
        "Pass The Test is the Online Assessment/Survey software to assess the skill sets for specific domain, workgroups, fieldworkers",
      image: test,
    },
    {
      name: "ERP Expert",
      description:
        "“ERP Expert” the ERP Solution to manage business process in real time and manages and integrates organization’s financials, supply chain, operations, reporting, manufacturing, and human resource activities.",
      image: erp,
    },
    {
      name: "E - Shopping",
      description:
        "“E-shopping” is the E-Commerce solution to do business for retail industry and start-ups, it’s a web-based and mobile app based as well to sell their products by using our E-Commerce applications. This product helps to add products, inventory management and suites for various verticals.",
      image: eshopping,
    },
    {
      name: "Bill POS",
      description:
        "“Bill POS” is the Billing solution will support to use with integrated POS systems and barcode generation methods to manage the inventory. This product will be used for various industries who needs billing solutions to manage their business.",
      image: pos,
    },
  ];
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "top",
      }}
    >
      <Box
        sx={{
          height: isMobile
            ? "50vh"
            : isTablet
            ? "40vh"
            : isDesktop
            ? "60vh"
            : "60vh",
          width: "100vw",
          backgroundImage: `url(${productBackground})`,
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: isTablet
            ? "flex-end"
            : isMobile
            ? "flex-end"
            : isDesktop
            ? "flex-end"
            : "flex-end",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Inter Normal",
            fontSize: isMobile ? "30px" : isTablet ? "40px" : "50px",
            fontWeight: 700,
            mb: 2,
            lineHeight: "normal",
          }}
        >
          Products
        </Typography>

        <Typography
          sx={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Inter Normal",
            fontSize: isMobile ? "14px" : isTablet ? "16px" : "20px",
            fontWeight: 400,
            width: isMobile ? "80%" : "50%",
            lineHeight: "normal",
            mb: 2,
          }}
        >
          Technowtechies is well renowned as a leading IT Transformation
          Architect company, with an impeccable track record for designing,
          developing,
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#C9C9FF",
        }}
      >
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",

            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: isDesktop ? "20vh" : "15vh",
          }}
        >
          {productList.map((product, index) => (
            <Box
              sx={{
                height: isMobile ? "70vh" : "503px",
                width: "80%",
                mt: 3,
                mb: 2,
                backgroundImage: `url(${productdescBackground})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor:
                  "fill: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 55.73%, rgba(255, 255, 255, 0.20) 155.73%)",

                strokeWidth: "1px",
                stroke: "#FFF",
                backdropFilter: "blur(10px)",
                borderRadius: isMobile ? "20px" : "12px",
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "90%",
                  width: "90%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {index % 2 === 0 ? (
                  <>
                    <Box
                      sx={{
                        height: isMobile ? "65vw" : "443px",
                        width: isMobile ? "65vw" : "443px",
                        background:
                          "radial-gradient(circle, #5872FBA3, #97C5FF)",
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "90%",
                          width: "90%",
                          background: `conic-gradient(
          from 0deg,
          rgba(255, 255, 255, 0.4) 55.73%,
          rgba(255, 255, 255, 0.2) 155.73%,
          #FFFFFF 25.85%, 
          rgba(241, 158, 255, 0.67) 112.41%
        )`,
                          borderRadius: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={product.image} height="100%" width="100%" />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexShrink: 1,
                        width: isMobile ? "100%" : "60%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontFamily: "Inter Normal",
                          fontSize: isMobile
                            ? "25px"
                            : isTablet
                            ? "30px"
                            : "35px",
                          fontWeight: 700,
                          lineHeight: "normal",
                        }}
                      >
                        {product.name}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#000",

                          fontFamily: "Inter Normal",
                          fontSize: isMobile
                            ? "16px"
                            : isTablet
                            ? "18px"
                            : "20px",
                          fontWeight: 400,
                          width: isMobile ? "80%" : isTablet ? "80%" : "50%",
                          lineHeight: "normal",
                          mt: 2,
                        }}
                      >
                        {product.description}
                      </Typography>
                    </Box>
                  </>
                ) : isMobile ? (
                  <>
                    <Box
                      sx={{
                        height: isMobile ? "65vw" : "443px",
                        width: isMobile ? "65vw" : "443px",
                        background:
                          "radial-gradient(circle, #5872FBA3, #97C5FF)",
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "90%",
                          width: "90%",
                          background: `conic-gradient(
          from 0deg,
          rgba(255, 255, 255, 0.4) 55.73%,
          rgba(255, 255, 255, 0.2) 155.73%,
          #FFFFFF 25.85%, 
          rgba(241, 158, 255, 0.67) 112.41%
        )`,
                          borderRadius: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={product.image} height="100%" width="100%" />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexShrink: 1,
                        width: isMobile ? "100%" : "60%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontFamily: "Inter Normal",
                          fontSize: isMobile
                            ? "25px"
                            : isTablet
                            ? "25px"
                            : "35px",
                          fontWeight: 700,
                          lineHeight: "normal",
                        }}
                      >
                        {product.name}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#000",

                          fontFamily: "Inter Normal",
                          fontSize: isMobile
                            ? "16px"
                            : isTablet
                            ? "16px"
                            : "20px",
                          fontWeight: 400,
                          width: isMobile ? "80%" : "50%",
                          lineHeight: "normal",
                          mt: 2,
                        }}
                      >
                        {product.description}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexShrink: 1,
                        width: isMobile ? "100%" : "60%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontFamily: "Inter Normal",
                          fontSize: isMobile ? "25px" : "35px",
                          fontWeight: 700,
                          lineHeight: "normal",
                        }}
                      >
                        {product.name}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#000",

                          fontFamily: "Inter Normal",
                          fontSize: isMobile ? "16px" : "20px",
                          fontWeight: 400,
                          width: isMobile ? "80%" : isTablet ? "80%" : "50%",
                          lineHeight: "normal",
                          mt: 2,
                        }}
                      >
                        {product.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: isMobile ? "65vw" : "443px",
                        width: isMobile ? "65vw" : "443px",
                        background:
                          "radial-gradient(circle, #5872FBA3, #97C5FF)",
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "90%",
                          width: "90%",
                          background: `conic-gradient(
          from 0deg,
          rgba(255, 255, 255, 0.4) 55.73%,
          rgba(255, 255, 255, 0.2) 155.73%,
          #FFFFFF 25.85%, 
          rgba(241, 158, 255, 0.67) 112.41%
        )`,
                          borderRadius: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={product.image} height="80%" width="80%" />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
