import { Toolbar, Box, IconButton, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import logo from "../../assets/logo.png";
import { useNavigate, NavLink } from "react-router-dom";
import { useState } from "react";
import { useMediaQueries } from "../../breakPoints";
export default function MenuItems(props) {
  const navigate = useNavigate();
  const { selectedPage, setSelectedPage } = props;
  const { isLargeDesktop, isDesktop, isTablet, isMobile } = useMediaQueries();

  const handleButtonClick = (to, index) => {
    navigate(`${to}`);
  };
  const navigationItems = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "Services",
      to: "/services",
    },
    {
      name: "About US",
      to: "/aboutus",
    },
  ];
  return (
    <AppBar
      position="fixed"
      contained
      sx={{
        backgroundColor: "#FFF",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Toolbar>
        <IconButton
          size={isMobile ? "small" : isTablet ? "medium" : "large"}
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: isMobile ? -2 : 2 }}
          disableRipple
        >
          <img
            src={logo}
            height={isMobile && "30px"}
            width={isMobile && "80px"}
          />
        </IconButton>
      </Toolbar>
      {/* <Toolbar>
        {navigationItems.map((item, index) => (
          <Button
            key={index}
            sx={{
              color: "#2B0D4B",

              fontFamily: selectedPage === index ? "Inter" : "Inter Normal",
              fontSize: selectedPage === index ? "20px" : "18px",
              fontWeight: selectedPage === index ? 800 : 400,
              lineHeight: "normal",
            }}
            onClick={() => handleButtonClick(item.to, index)}
          >
            {item.name}
          </Button>
        ))}
      </Toolbar> */}
    </AppBar>
  );
}
