import { Box, Toolbar } from "@mui/material";
import MenuItems from "../components/Common/Appbar";
import { useEffect } from "react";
import PageTitleBox from "../components/Common/pageTitleBox";
import homeIcon from "../assets/hometop.png";
import Footer from "../components/Common/footer";
import Description from "../components/HomePageComponents/description";
import ServicesList from "../components/HomePageComponents/servicesList";
import ProductList from "../components/HomePageComponents/productList";
import HomeForm from "../components/HomePageComponents/homeForm";
export default function HomeScreen(props) {
  const { selectedPage, setSelectedPage } = props;
  const pageTitleDetails = {
    title: "A complete IT Solution Provider",
    description:
      "Expert in Software solution for your needs Service Delivery for IT Security, IT Infra Monitoring,HRMS, LMS and Asset Management",
    icon: homeIcon,
  };

  useEffect(() => {
    setSelectedPage(0);
  }, []);
  return (
    <>
      <Box>
        <MenuItems selectedPage={selectedPage} />

        <Toolbar />
        <PageTitleBox pageTitleDetails={pageTitleDetails} selectedPage={0} />
        {/* TechNowTechies Description */}
        <Description />
        <ServicesList />
        <ProductList />
        <HomeForm />

        <Footer />
      </Box>
    </>
  );
}
